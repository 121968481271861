import "./App.css";
import { Route, Routes } from "react-router-dom";
import PageHome from "./PageHome";
import PageTrack from "./PageTrack";
import { useEffect, useState } from "react";

function App() {
  // Fetch Data
  const [datas, setDatas] = useState([]);
  async function fetchData() {
    const url =
      "https://script.google.com/macros/s/AKfycby0epaq8I6EW_GuXNqTYMq-RbQ4z-s9Hf3VW7vI84DB10F1AO8Vj3l_krudwHQcnVcy/exec";
    const res = await fetch(url);
    const resJson = await res.json();
    setDatas(resJson);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<PageHome />} />
        <Route path="/:trackSearch" element={<PageTrack datas={datas} />} />
      </Routes>
    </div>
  );
}

export default App;
