import { useState } from "react";
import { ExternalLink } from "react-external-link";
import { Link, useParams } from "react-router-dom";
import "./PageTrack.css";

function PageTrack(props) {
  const { datas } = props;
  const { trackSearch } = useParams();
  const [searchInput, setSearchInput] = useState(trackSearch);

  // Flash or Kerry or J&T
  function createTrackButton(trackno) {
    if (typeof trackno === "number") {
      return (
        <ExternalLink
          className="j-and-t-button"
          href={`https://www.jtexpress.co.th/index/query/gzquery.html?bills=${trackno}`}
        >
          ติดตาม
        </ExternalLink>
      );
    } else if (trackno.toString().startsWith("TH")) {
      return (
        <ExternalLink
          className="flash-button"
          href={`https://www.flashexpress.com/tracking/?se=${trackno}`}
        >
          ติดตาม
        </ExternalLink>
      );
    } else if (trackno.toString().startsWith("SXF")) {
      return (
        <ExternalLink
          className="kerry-button"
          href={`https://th.kerryexpress.com/en/track/?track=${trackno}`}
        >
          ติดตาม
        </ExternalLink>
      );
    }
  }

  // Element
  let nameElement = null;

  const datasFilter = datas.filter((data) => {
    try {
      return data.name.toLowerCase().includes(trackSearch.toLowerCase());
    } catch {
      console.log("Error in:", data);
      return null;
    }
  });

  if (trackSearch.length <= 3) {
    nameElement = (
      <div className="track-search-error">
        <img src="/images/more-than-3-char-icon.svg" alt="more than 3 char" />
        <h2>ขั้นต่ำ 3 ตัวอักษร</h2>
        <p>กรุณากรอกชื่อมากกว่า "3 ตัวอักษร"</p>
      </div>
    );
  } else if (trackSearch.length > 3 && datasFilter.length > 0) {
    nameElement = datasFilter.map((data) => {
      // split ไว้สำหรับแยก string ออกเป็น arrat
      // /[\n,]+/ มี seperator เป็น \n และ ,
      const subjects = data.subject.split(/[\n,]+/);
      const subjectElements = subjects.map((subject, index) => {
        return <p key={index}>{subject}</p>;
      });

      const trackButton = createTrackButton(data.tracking);
      return (
        <div key={data.id} className="profile-item">
          <h2>{data.name}</h2>
          <div className="gray-background">
            <div className="profile-subjects">{subjectElements}</div>
          </div>
          <div className="profile-tracking">
            <p>Tracking No.</p>
            <h3>{data.tracking}</h3>
            {trackButton}
          </div>
        </div>
      );
    });
  } else {
    nameElement = (
      <div className="track-search-error">
        <img src="/images/not-found-icon.svg" alt="not found" />
        <h2>ไม่เจอ "{trackSearch}"</h2>
        <p>
          ไม่มีสถานะจัดส่งของคุณ <br />
          ถ้าเพิ่งกดซื้อกรุณารอภายใน 24 ชม.
        </p>
      </div>
    );
  }
  // Buttom Function
  function onInputChange(event) {
    setSearchInput(event.target.value);
  }

  function onClearValue(event) {
    event.preventDefault();
    const inputValue = event.currentTarget.previousElementSibling;
    setSearchInput("");
    inputValue.focus();
  }

  let clearButton = null;
  if (!!searchInput) {
    clearButton = (
      <button className="clear-input" onClick={onClearValue}>
        <img
          className="clear-icon"
          src="/images/pagetrack-clear-icon.svg"
          alt="clear"
        />
      </button>
    );
  }

  return (
    <section>
      <div className="header-wrapping">
        <div className="search-header">
          <div className="heading">
            <div>
              <Link to={"/"}>
                <img src="/images/pagetrack-header-icon.svg" alt="icon" />
              </Link>
            </div>
            <div>
              <h1>สถานะส่งหนังสือ</h1>
            </div>
          </div>
          <form className="pagetack-search-form">
            <Link to={`/${searchInput}`}>
              <button className="submit-button" type="submit">
                <img
                  className="search-icon"
                  src="/images/pagetrack-search-icon.svg"
                  alt="search"
                />
              </button>
            </Link>
            <input
              className="search-input"
              type="text"
              placeholder="ชื่อจริง..."
              value={searchInput}
              onChange={onInputChange}
            />
            {clearButton}
          </form>
        </div>
      </div>
      <div className="track-container">{nameElement}</div>
    </section>
  );
}

export default PageTrack;
