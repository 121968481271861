import { useState } from "react";
import { Link } from "react-router-dom";
import './PageHome.css'

function PageHome() {
    const [searchInput, setSearchInput] = useState("")
    
    function onInputChange(event) {
        setSearchInput(event.target.value)
    }

    return (
        <section className="app-section">
            <div className="app-container">
                <div className="search-container">
                    <form className="search-form">
                        <input
                            type="text"
                            placeholder="ชื่อจริง..."
                            value={searchInput}
                            onChange={onInputChange}
                        />
                        <Link to={`/${searchInput}`}><button type="submit"><img className="search-icon" src="/images/pagehome-search-icon.svg" alt="search"/></button></Link>
                        <div className="hero">
                            <img src="/images/pagehome-logo.svg" alt="logo"/>
                            <h1>สถานะส่งหนังสือ</h1>
                        </div>      
                        <h3>be-engineer</h3>                  
                    </form>
                </div>
            </div>
        </section>
    )
}

export default PageHome;